import Axios from 'axios'
import {BasketApiUrls} from '../utils/BasketApiUrls';
import { getPaymentRequestDataUrl, confirmPaymentRequestAmountUrl, startPaymentRequestUrl, startAfterpayPaymentRequestUrl, getPaymentFromAfterpayRequestUrlByTransactionId, createPaymentRequestForSalesRecoveryUrl } from '@utils/ApiUrls';
import { GiftingDetails } from '@models/Checkout/GiftingDetails/GiftingDetails';


const placeOrder = async (apiUrl: string, basketId:string, deliveryType:number, isLayby:boolean, autoPay:boolean,paymentFrequency:string,entities: Array<any>, giftingDetails: GiftingDetails, overrideNextPaymentDue: string, isA2ARedesignOrder: boolean) =>{
    const url = BasketApiUrls.placeOrderUrl(apiUrl,basketId, deliveryType,isLayby,autoPay,paymentFrequency,overrideNextPaymentDue, isA2ARedesignOrder);
    const data = {
        entities : entities,
        giftingDetails: giftingDetails
    }
    
    const result = await Axios.post(url,data);
    return result.data;
}
const paymentOnlineRequest = async(apiUrl: string, basketId:string, method: number, amount?:number) =>{
    const url = BasketApiUrls.paymentOnlineRequestUrl(apiUrl, basketId, method, amount);
    const result = await Axios.get(url);
    return result.data.Data;
}
const getPaymentFromAfterpayRequest = async(apiUrl: string, basketId:string, amount?:number) =>{
    const url = BasketApiUrls.getPaymentFromAfterpayRequestUrl(apiUrl,basketId,amount);
    const result = await Axios.post(url);
    return result.data;
}
const getPaymentFromAfterpayRequestByTransactionId = async(apiUrl: string, transactionId?:number, amount?:number) =>{
    const url = getPaymentFromAfterpayRequestUrlByTransactionId(apiUrl,transactionId,amount);
    const result = await Axios.post(url);
    return result.data;
}
const authAfterpayPayment = async(apiUrl:string, token: string) =>{
    const url = BasketApiUrls.getAfterpayAuthRequestUrl(apiUrl,token);
    const result = await Axios.post(url);
    return result.data;
}
const getPaymentRequestData = async(apiUrl: string, token: string, fromsalesforce: boolean) =>{
    const url = getPaymentRequestDataUrl(apiUrl, token, fromsalesforce);
    const result = await Axios.get(url);
    return result.data.Data;
}
const confirmPaymentRequestAmount = async(apiUrl: string, token: string, amount: number, paymentMethod: number) => {
    const url = confirmPaymentRequestAmountUrl(apiUrl, token, amount, paymentMethod);
    const result = await Axios.get(url);
    return result.data.Data;
}
const startPaymentRequest = async(apiUrl: string, token: string, amount: number, paymentMethod: number) => {
    const url = startPaymentRequestUrl(apiUrl, token, amount, paymentMethod);
    const result = await Axios.get(url);
    return result.data;
}
const startAfterpayPaymentRequest = async(apiUrl: string, token: string, amount: number) => {
    const url = startAfterpayPaymentRequestUrl(apiUrl, token, amount);
    const result = await Axios.get(url);
    return result.data;
}
const createPaymentRequestForSalesRecovery = async(apiUrl: string, key: string) => {
    const url = createPaymentRequestForSalesRecoveryUrl(apiUrl, key);
    const result = await Axios.get(url);
    return result.data;
}

export const PaymentService = {
    placeOrder: placeOrder,
    paymentOnlineRequest:paymentOnlineRequest,
    getPaymentRequestData: getPaymentRequestData,
    confirmPaymentRequestAmount: confirmPaymentRequestAmount,
    startPaymentRequest: startPaymentRequest,
    getPaymentFromAfterpayRequest: getPaymentFromAfterpayRequest,
    authAfterpayPayment: authAfterpayPayment,
    startAfterpayPaymentRequest: startAfterpayPaymentRequest,
    getPaymentFromAfterpayRequestByTransactionId : getPaymentFromAfterpayRequestByTransactionId,
    createPaymentRequestForSalesRecovery: createPaymentRequestForSalesRecovery
}