import Axios from 'axios'
import { getTransactionOrderData } from '@utils/ApiUrls';

const getOrderConfirmationData = async (apiUrl: string, id: string) => {
    const url = getTransactionOrderData(apiUrl,id);
    return Axios.get(url);
}

export const OrderConfirmationService = {
    getOrderConfirmationData: getOrderConfirmationData
}